.react-dots-loader {
  line-height: 0px;
}

.react-dots-loader__circle {
  border-radius: 50%;
  margin-left: 10px;
  background-color: black;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  display: inline-block;
}

.react-dots-loader__circle--one {
  opacity: 1;
  -webkit-animation: react-dots-loader__animation--one 1s infinite linear;
          animation: react-dots-loader__animation--one 1s infinite linear;
}

.react-dots-loader__circle--two,
.react-dots-loader__circle--three {
  opacity: 0.25;
}

.react-dots-loader__circle--two {
  -webkit-animation: react-dots-loader__animation--two 1s infinite linear;
          animation: react-dots-loader__animation--two 1s infinite linear;
}
.react-dots-loader__circle--three {
  -webkit-animation: react-dots-loader__animation--three 1s infinite linear;
          animation: react-dots-loader__animation--three 1s infinite linear;
}

@-webkit-keyframes react-dots-loader__animation--one {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-dots-loader__animation--one {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes react-dots-loader__animation--two {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}

@keyframes react-dots-loader__animation--two {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}

@-webkit-keyframes react-dots-loader__animation--three {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes react-dots-loader__animation--three {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
